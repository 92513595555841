import React, { lazy } from "react";
import frontRoutes from "./pages/front-pages/frontRoutes";
import { Loadable, Redirect, NotFound } from "./common";
const RAadmin = Loadable(lazy(() => import("./pages/RAadmin")));

const raAdminRoutes = [
    {
        path: "/*",
        element: <RAadmin />,
    },
];

const routes = [
    ...frontRoutes,
    ...raAdminRoutes,
    { path: "/", element: <Redirect /> },
    { path: "*", element: <NotFound /> },
];

export default routes;
