import React, { Fragment } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { SocketProvider } from './context/SocketContext';

const App = () => {
  const content = useRoutes(routes);
  return (
    <Fragment>
      <SocketProvider>
        {content}
      </SocketProvider>
    </Fragment>
  );
};

export default App;
