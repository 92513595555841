import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import colors from 'app/assets/colors/colors';
import { Icon } from '@mui/material';
import { Strong, Span } from './Typography';

const StyledStrong = styled(Strong)(() => ({
    color: colors.TCG_BLACK,
}));

const InputWrapper = styled('div')(
    ({ theme }) => `
  width: 100%;

  border: 1px solid ${theme.palette.mode === 'dark' ? '#434343' : '#d9d9d9'};
  box-shadow :  0px 5px 5px -3px rgb(0 0 0 / 6%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 4%) !important;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  height:38px;
  padding: 4px 6px !important;
  color:${colors.TCG_DARK_GRAY}
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : colors.TCG_BLACK
        };
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${theme.palette.mode === 'dark'
            ? 'rgba(255,255,255,0.65)'
            : 'rgba(0,0,0,.85)'
        };
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px !important;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`
);

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
                }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
            {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const { ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'
        }`,
    boxShadow: `0 8px 24px ${theme.palette.mode === 'light'
        ? 'rgba(149, 157, 165, 0.2)'
        : 'rgb(1, 4, 9)'
        }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
        }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
            }`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${theme.palette.mode === 'light'
                ? 'rgba(3, 102, 214, 0.3)'
                : 'rgb(12, 45, 107)'
                }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
        },
    },
}));

export default function MultiSelect({
    label,
    data = [],
    setValue,
    value,
    placeholder,
    readOnly,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();

    React.useEffect(() => {
        setPendingValue(value);
    }, [value]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'Role' : undefined;

    const getLabel = (value) => {
        if (value.length === 0) {
            return placeholder;
        } else if (value.length === 1) {
            return value[0].name;
        } else {
            return value.length + ' selected';
        }
    };

    const _setPendingValues = (newValues) => {
        setPendingValue(newValues);
        setValue(newValues);
    };

    const checkSelected = (id) => {
        return pendingValue.some((obj) => obj.id === id);
    };

    return (
        <React.Fragment>
            <StyledStrong sx={{ paddingBottom: 1 }}>{label}</StyledStrong>
            <InputWrapper onClick={handleClick}>
                <Box
                    sx={{
                        mt: '2px',
                        height: 20,
                        padding: '0px 5px',
                        lineHeight: '10px',
                        borderRadius: '2px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Span
                        sx={{
                            mt: '2px',
                            height: 20,
                            justifyContent: 'space-between',
                            fontSize: '14px',
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {getLabel(value)}
                    </Span>

                    <Icon
                        sx={{
                            mr: 1,
                            color: colors.TCG_BLACK,
                        }}
                    >
                        keyboard_arrow_down
                    </Icon>
                </Box>
            </InputWrapper>
            <StyledPopper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="bottom-start"
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        <Box
                            sx={{
                                borderBottom: `1px solid ${theme.palette.mode === 'light'
                                    ? '#eaecef'
                                    : '#30363d'
                                    }`,
                                padding: '8px 10px',
                                fontWeight: 600,
                            }}
                        >
                            {value.length === data.length ? (
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        setPendingValue([]);
                                        setValue([]);
                                    }}
                                >
                                    <Strong> Deselect all</Strong>
                                </Button>
                            ) : (
                                <Button
                                    size="small"
                                    color="primary"
                                    onClick={() => {
                                        setPendingValue(data);
                                        setValue(data);
                                    }}
                                >
                                    <Strong> Select all</Strong>
                                </Button>
                            )}
                        </Box>
                        <Autocomplete
                            readOnly={readOnly}
                            open
                            multiple
                            onClose={(event, reason) => {
                                if (reason === 'escape') {
                                    handleClose();
                                }
                            }}
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                if (
                                    event.type === 'keydown' &&
                                    event.key === 'Backspace' &&
                                    reason === 'removeOption'
                                ) {
                                    return;
                                }
                                _setPendingValues(newValue);
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No data"
                            renderOption={(props, option, { selected }) => {
                                const _selectecd = checkSelected(option.id);
                                return (
                                    <li {...props} key={option.id}>
                                        <Box
                                            component={DoneIcon}
                                            sx={{
                                                width: 17,
                                                height: 17,
                                                mr: '5px',
                                                ml: '-2px',
                                            }}
                                            style={{
                                                visibility: _selectecd
                                                    ? 'visible'
                                                    : 'hidden',
                                            }}
                                        />
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 14,
                                                height: 14,
                                                flexShrink: 0,
                                                borderRadius: '3px',
                                                mr: 1,
                                                mt: '2px',
                                            }}
                                            style={{
                                                backgroundColor: _selectecd
                                                    ? "#000000"
                                                    : colors.TCG_GRAY,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                '& span': {
                                                    color:
                                                        theme.palette.mode ===
                                                            'light'
                                                            ? '#586069'
                                                            : '#8b949e',
                                                },
                                            }}
                                        >
                                            {option.name}
                                        </Box>
                                        <Box
                                            component={CloseIcon}
                                            sx={{
                                                opacity: 0.6,
                                                width: 18,
                                                height: 18,
                                            }}
                                            style={{
                                                visibility: _selectecd
                                                    ? 'visible'
                                                    : 'hidden',
                                            }}
                                        />
                                    </li>
                                );
                            }}
                            options={[...data].sort((a, b) => {
                                // Display the selected data first.
                                let ai = value.indexOf(a);
                                ai =
                                    ai === -1
                                        ? value.length + data.indexOf(a)
                                        : ai;
                                let bi = value.indexOf(b);
                                bi =
                                    bi === -1
                                        ? value.length + data.indexOf(b)
                                        : bi;
                                return ai - bi;
                            })}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder={placeholder}
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    );
}

MultiSelect.defaultProps = {
    placeholder: 'Select data',
    label: 'filter by data',
    value: [],
    setValue: () => { },
    readOnly: false,
};
