import React, { createContext, useContext, useEffect, useState } from "react";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [selectedChat, setSelectedChat] = useState();
  const [user, setUser] = useState();
  const [notification, setNotification] = useState([]);
  const [chats, setChats] = useState();
  const [open, setOpen] = React.useState(false);


  useEffect(() => {
    const user_data = {
      email: localStorage.getItem("email"),
      isAdmin: localStorage.getItem("role") === "ADMIN",
      name: localStorage.getItem("name"),
      pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      token: localStorage.getItem("token"),
      _id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
    }
    setUser(user_data);
  }, []);

  return (
    <ChatContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        user,
        setUser,
        notification,
        setNotification,
        chats,
        setChats,
        setOpen,
        open
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatState = () => {
  return useContext(ChatContext);
};

export default ChatProvider;
