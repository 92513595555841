export const API_URL = process.env.REACT_APP_MODE ===
    "dev" ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL;
    
export const REACT_APP_BASE_URL = process.env.REACT_APP_MODE ===
    "dev" ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL;

export const REACT_APP_REST_URL = process.env.REACT_APP_MODE ===
    "dev" ? process.env.REACT_APP_REST_URL_DEV
    : process.env.REACT_APP_REST_URL_LIVE;

export const SOCKET_URL_DEV = process.env.REACT_APP_MODE ===
    "dev" ? process.env.REACT_APP_SOCKET_URL_DEV
    : process.env.REACT_APP_SOCKET_URL_LIVE;

export const API_ZENDESK_URL = process.env.REACT_APP_MODE ===
    "dev" ? process.env.REACT_APP_API_ZENDESK_URL_DEV
    : process.env.REACT_APP_API_ZENDESK_URL;


export const config = {
    API_ZENDESK_URL,
    API_URL
}

export const REACT_APP_GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;   