import React, { createContext, useContext } from 'react';
import { SOCKET_URL_DEV } from 'config.js';
import io from "socket.io-client";

const ENDPOINT = SOCKET_URL_DEV;
const socket = io(ENDPOINT);

const SocketContext = createContext({
    socket: null,
});

export const SocketProvider = ({ children }) => {
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role");

    React.useEffect(() => {
        if (username) {
            socket.emit("ORDER_SETUP", { username: username });
            socket.on("ORDER_SETUP_CONNECTED", () => {
                console.log("Portal user CONNECTED SOCKET");
            });
        }
        return () => {
            if (username) {
                socket.off("ORDER_SETUP_CONNECTED");
            }
        };
    }, [username])

    const reconnect = () => socket.emit("ORDER_SETUP", { username: username });

    return (
        <SocketContext.Provider
            value={{
                socket,
                reconnect
            }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);