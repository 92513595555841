import { Loadable } from "../../common";
import { lazy } from "react";

const NotFound = Loadable(lazy(() => import("./NotFound")));
const Reset = Loadable(lazy(() => import("./reset/Reset")));
const Register = Loadable(lazy(() => import("./register/Register")));
const ForgotPass = Loadable(lazy(() => import("./forgot-pass/ForgotPass")));
const Otp = Loadable(lazy(() => import("./otp/Otp")));
const UserOTP = Loadable(lazy(() => import("./login/UserOTP")));
const AdminLogin = Loadable(lazy(() => import("./login/AdminLogin")));

const frontRoutes = [
    {
        path: "/otp",
        element: <Otp />,
    },
    {
        path: "/admin",
        element: <AdminLogin />,
    },
    {
        path: "/user-otp",
        element: <UserOTP />,
    },
    {
        path: "/verification",
        element: <Reset />,
    },
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/resetpass",
        element: <ForgotPass />,
    },
    {
        path: "/page/404",
        element: <NotFound />,
    }
];

export default frontRoutes;
