import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@mui/styled-engine";
import ChatProvider from "app/pages/chat/Context/ChatProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <StyledEngineProvider>
        <SnackbarProvider
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
            <BrowserRouter basename="/">
                <ChatProvider>
                    <App />
                </ChatProvider>
            </BrowserRouter>
        </SnackbarProvider>
    </StyledEngineProvider>
);
